import { useTranslate } from "@tolgee/react"
import { gql } from "graphql-request"
import { GenericPageHeader } from "../../../../components"
import { ResponsivePaddingWrapper } from "../../../../layouts/TabLayout"
import { useAssets } from "../../../../providers"
import { useRiskOutlook } from "../provider"
import WidgetWrapper from "../../../../climateui/components/Widgets/WidgetWrapper/WidgetWrapper"

import { DateTime } from "luxon"
import RiskOverviewWidget from "./RiskOverviewWidget"

export interface IStageRiskOverview {
    stage_name: string
    start_date: Date | string
    end_date: Date | string
}

interface IHazardRiskStats {
    plot: {
        lastValue: {
            date: string
            y: number
        }
    }[]
}
export interface IRiskCountryOverviewModel {
    region_id: string
    asset_id: string
    stages: IStageRiskOverview[]
    excessive_heat: IHazardRiskStats
    excessive_cold: IHazardRiskStats
    drought: IHazardRiskStats
    excessive_rain: IHazardRiskStats
    bounds: number[]
    risk_stats: {
        results: {
            hazard_key: string
            data: {
                timeseries: {
                    date: string
                    terciles: number[]
                    value: number
                }[]
            }
        }[]
    }
    highest_risk_score: number
    region: {
        id: string
        name: string
        resolution: number
        geojson: GeoJSON.Geometry
    }
}

export interface IRiskOverview {
    asset_id: string
    asset_name: string
    asset_models: IRiskCountryOverviewModel[]
}

const RiskOverview = () => {
    const { t } = useTranslate()
    const { isLoading, countries } = useRiskOutlook()
    const { allAssets } = useAssets()

    const riskOverviewQuery = gql`
        query (
            $region_ids: [String]
            $asset_ids: [String]
            $init_time: String
        ) {
            risk_overview_query: yield_outlook_model(
                filter: {
                    region_ids: $region_ids
                    asset_ids: $asset_ids
                    risk_status: "active"
                }
            ) {
                results {
                    region_id
                    excessive_heat: risk_historical_stats(
                        filter: { hazard_key: "excessive_heat" }
                    ) {
                        plot(configs: [{ date: "year", y: "value" }]) {
                            firstValue {
                                date
                                y
                            }
                            lastValue {
                                date
                                y
                            }
                        }
                        results {
                            data {
                                risk_bounds
                            }
                        }
                    }
                    excessive_cold: risk_historical_stats(
                        filter: { hazard_key: "excessive_cold" }
                    ) {
                        plot(configs: [{ date: "year", y: "value" }]) {
                            firstValue {
                                date
                                y
                            }
                            lastValue {
                                date
                                y
                            }
                        }
                        results {
                            data {
                                risk_bounds
                            }
                        }
                    }
                    drought: risk_historical_stats(
                        filter: { hazard_key: "drought" }
                    ) {
                        plot(configs: [{ date: "year", y: "value" }]) {
                            firstValue {
                                date
                                y
                            }
                            lastValue {
                                date
                                y
                            }
                        }
                        results {
                            data {
                                risk_bounds
                            }
                        }
                    }
                    excessive_rain: risk_historical_stats(
                        filter: { hazard_key: "excessive_rain" }
                    ) {
                        plot(configs: [{ date: "year", y: "value" }]) {
                            firstValue {
                                date
                                y
                            }
                            lastValue {
                                date
                                y
                            }
                        }
                        results {
                            data {
                                risk_bounds
                            }
                        }
                    }
                    asset_id
                    stages(filter: { current_date: $init_time }) {
                        stage_name
                        start_date
                        end_date
                    }
                }
            }
        }
    `

    return (
        <ResponsivePaddingWrapper>
            <div className="flex flex-col h-full overflow-hidden grow gap-4">
                <GenericPageHeader
                    pageTitle={t("RiskOutlookSummary")}
                    right={undefined}
                    bottom={
                        <h1 className="w-full text-end body-sm text-gray-60">
                            {`${t("refreshed")} ${DateTime.now().toLocaleString(
                                DateTime.DATE_FULL
                            )}
                            `}
                        </h1>
                    }
                    bottomSectionPadding="pt-0"
                />
                <div className="overflow-y-auto h-3/4">
                    <WidgetWrapper
                        component={RiskOverviewWidget}
                        query={riskOverviewQuery}
                        selectors={{ $data: "risk_overview_query.results[]" }}
                        filters={[
                            {
                                propName: "region_ids",
                                value: countries && Object.keys(countries),
                                loading: isLoading,
                            },
                            {
                                propName: "asset_ids",
                                value: allAssets && Object.keys(allAssets),
                            },
                            {
                                propName: "init_time",
                                value: DateTime.now().toUTC().toISODate(),
                            },
                        ]}
                    />
                </div>
                <h1 className="body-sm text-gray-60">
                    {t(
                        "contactToAddRiskCropDescription",
                        "Please email your Account Executive or Customer Success contact to add a new crop to Seasonal Risk Outlook."
                    )}
                </h1>
            </div>
        </ResponsivePaddingWrapper>
    )
}

export default RiskOverview
