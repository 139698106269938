import { useTranslate } from "@tolgee/react";
import { categoryToColor, getRiskCategoryCoding, RISKOUTLOOK_VARIABLES } from '../riskOutlookUtils';
import { IRiskOutlookOverviewHazardModel } from "../../../../types";
import LoadingAnimation from "../../../../climateui/components/LoadingAnimation";


const RiskOverviewScoreDisplay = (props: {data: any[], loading: boolean}) => {
    const { t } = useTranslate();
    const { data, loading } = props;

    if (loading) {
        return (
            <div className="flex justify-center mt-5 body-lg">
                <LoadingAnimation />
            </div>
        )
    }


    const hazardModels: IRiskOutlookOverviewHazardModel[] = []
    RISKOUTLOOK_VARIABLES.forEach((variable: string) => {
        const score = data[0][variable].plot[0].lastValue.y
        const risk_bounds = data[0][variable].results[0].data.risk_bounds
        const riskType = variable
        const category = getRiskCategoryCoding(score, risk_bounds)

        hazardModels.push({
            score,
            riskType,
            category
        })
    });
    
    const highestRiskModel = hazardModels.sort((a, b) => b.score - a.score)[0]

    const drivenBy = hazardModels.filter(hm => highestRiskModel.category === hm.category)

    return (
        <>
        <div className="flex flex-col justify-between" style={{height: "100%"}}>
            <div className="label-lg">{t("outlookForUpcomingConditions", "Outlook for upcoming conditions")}</div>
            <div className={`text-${categoryToColor[highestRiskModel.category]} flex flex-1 items-center justify-center`}>
                <div className={`bg-${categoryToColor[highestRiskModel.category]} h-3 rounded-full w-3 mr-1`}></div>
                <div className="title-lg text-center">{t(highestRiskModel.category)}</div>
            </div>
            {highestRiskModel.category != "lowRisk" && (
                <div className="w-full">
                    Driven by {drivenBy.map((item, index) => (
                        <>
                            <span key={"overview-"+index} className={`text-${categoryToColor[highestRiskModel.category]} font-bold `}>
                                {t(item.riskType)}
                            </span>
                            {index < drivenBy.length - 1 ? ', ' : ''}
                            {index === drivenBy.length - 2 ? ' and ' : ''}
                        </>
                    ))}
                </div>
            )
            }

        </div>

       
        </>
    );
};

export default RiskOverviewScoreDisplay;