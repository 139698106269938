import { useTranslate } from "@tolgee/react"
import { useAssets } from "../../../../providers"
import { columns } from "../RiskOverviewTableUtils"
import { IRiskCountryOverviewModel, IRiskOverview } from "./RiskOverview"
import { Table } from "../../../../climateui/components"
import { useMemo } from "react"
import { RISKOUTLOOK_VARIABLES } from "../riskOutlookUtils"

interface RiskOverviewWidgetProps {
    data: IRiskCountryOverviewModel[]
    loading: boolean
}

const RiskOverviewWidget: React.FC<RiskOverviewWidgetProps> = ({ data, loading }) => {
    const { t } = useTranslate()
    const { allAssets } = useAssets()

    const processedData = useMemo(() => {
        if (!data) return []

        const assetModelsRelationObj: Record<string, IRiskCountryOverviewModel[]> = {}

        data.forEach((risk_model: any) => {
            let max_value = -Infinity;
            let max_risk_bounds: number[] = [];
            RISKOUTLOOK_VARIABLES.forEach(variable => {
                const current_value = risk_model[variable].plot[0].lastValue.y;
                if (current_value > max_value) {
                    max_value = current_value;
                    max_risk_bounds = risk_model[variable].results[0].data.risk_bounds;
                }
            });
            const assetId = risk_model?.asset_id

            if (!assetModelsRelationObj[assetId]) {
                assetModelsRelationObj[assetId] = []
            }

            assetModelsRelationObj[assetId].push({
                ...risk_model,
                highest_risk_score: max_value,
                bounds: max_risk_bounds
            })
        })

        return Object.entries(assetModelsRelationObj).map(([assetId, modelsArray]) => ({
            asset_id: assetId,
            asset_name: allAssets ? allAssets[assetId]?.name || "" : "",
            asset_models: modelsArray,
        }))

    }, [data, allAssets])

    return (
        <Table<IRiskOverview>
            data={processedData}
            columns={columns}
            noDataMessage={loading ? t("loadingCountryModels") : t("noModelsAvailable")}
        />
    )
}

export default RiskOverviewWidget
